<template>
  <div style="width: 80%; margin: auto">
    <b-overlay :show="show" rounded="sm">
      <div>
        <modelAddress ref="address"></modelAddress>
        <hr />
        <products ref="products"></products>
        <hr />
        <b-card>
          <b-row>
            <b-col md="12" xl="12">
              <b-card-header class="flex-column align-items-start">
                <b-card-title>วิธีการชำระเงิน </b-card-title>
                <b-card-text class="text-muted mt-25">
                  อย่าลืมคลิกตัวเลือกการชำระเงินที่ถูกต้อง
                </b-card-text>
              </b-card-header>
              <b-card-body>
                <b-form-group>
                  <b-form-radio
                    v-model="payment_method"
                    name="type"
                    class="mt-1"
                    value="card"
                  >
                    <img width="30px" :src="require('@/assets/visa.png')" alt="" />
                    <img width="30px" :src="require('@/assets/visa2.png')" alt="" />
                    บัตรเครดิต/บัตรเดบิต
                  </b-form-radio>
                  <div style="margin-left: 40px" v-if="payment_method === 'card'">
                    <hr />

                    <div v-if="card_credit">
                      <b-form-radio
                        v-model="set_credit"
                        class="mt-1"
                        :value="card_credit"
                      >
                        <b>{{ card_credit }}</b>
                      </b-form-radio>
                    </div>

                    <div @click="set_card(form_null, 0)" v-if="count_card_credit === 0">
                      <b-form-radio v-model="set_credit" class="mt-1" value="">
                        <b class="align-middle">เพิ่มบัตรเครดิต/บัตรเดบิต</b>
                      </b-form-radio>
                    </div>
                  </div>
                  <!-- <b-form-radio
                    v-model="payment_method"
                    class="mt-1"
                    value="QrCode"
                    name="type"
                  >
                    <img width="20px" :src="require('@/assets/pp.png')" alt="" />
                    Qr code
                  </b-form-radio> -->

                   <b-form-radio
                    v-model="payment_method"
                    class="mt-1"
                    value="PromptPay"
                    name="type"
                  >
                    พร้อมเพย์
                  </b-form-radio>
                  <div style="margin-left: 40px" v-if="payment_method === 'PromptPay'">
                    <hr />
                    <h6>
                      <b>
                        บัญชี บริษัท โซซิโอ จำกัด ธนาคารกสิกรไทย สาขาเซ็นทรัลพลาซา ขอนแก่น
                        <br />
                        เลขที่บัญชี 024-1-69678-2
                      </b>
                    </h6>

                    <b-col cols="12">
                      <b-img thumbnail fluid :src="image" />

                      <b-form-file
                        placeholder="อัพโหลดรูป"
                        ref="refInputEl"
                        type="file"
                        accept=".jpeg,.png,.jpg,GIF"
                        :hidden="true"
                        @change="onFileChange"
                      />
                    </b-col>

                    <hr />
                  </div>


                  <b-form-radio
                    v-model="payment_method"
                    class="mt-1"
                    value="installment"
                    name="type"
                  >
                    ผ่อนชำระ
                  </b-form-radio>
                  <div style="margin-left: 40px" v-if="payment_method === 'installment'">
                    <hr />
                    <b-row>
                      <b-col xl="5" md="4">
                        <h5><b>ค่างวดเงินผ่อน (Installment) </b></h5>
                        <div
                          v-for="(item, index) in banks"
                          :key="index"
                          @click="
                            (months = item.Installment), (months_data = item.Installment)
                          "
                        >
                          <b-form-radio
                            :value="item.source_omise"
                            v-model="set_bank"
                            class="m-1"
                          >
                            <img :src="item.logo" width="20px" />
                            {{ item.name_thai }}
                          </b-form-radio>
                        </div>
                      </b-col>
                      <b-col xl="5" md="5">
                        <!-- {{ months }} -->
                        <h5 v-if="set_bank"><b>เลือกเงื่อนไขการชำระเงิน</b></h5>
                        <div v-for="(item, index) in months" :key="index">
                          <b-form-radio
                            :value="item"
                            v-model="set_month"
                            class="m-1"
                            name="month"
                          >
                            {{
                              new Intl.NumberFormat("en-IN").format(
                                (
                                  ($refs.products.products.price *
                                    $refs.products.quantity) /
                                  item
                                ).toFixed(2)
                              )
                            }}
                            บาท/เดือน <br />

                            {{ item }} เดือน (ดอกเบี้ย 0%)
                          </b-form-radio>
                        </div>
                      </b-col>
                    </b-row>

                    <hr />
                  </div>
                </b-form-group>

                <hr class="my-2" />
                <b-button
                  v-if="set_credit && payment_method === 'card'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="omise_token_payments()"
                >
                  ยืนยันการชำระเงิน
                </b-button>
                 <b-button
                  v-if="payment_method === 'PromptPay'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="PromptPay()"
                >
                  ดำเนินการต่อ
                </b-button>
                
                <b-button
                  v-if="payment_method === 'QrCode'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="paymentQR()"
                >
                  ยืนยันการชำระเงิน
                </b-button>
                <b-button
                  v-if="payment_method === 'installment'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="omise_Create_installment_payment()"
                >
                  ยืนยันการชำระเงิน
                </b-button>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <b-modal
        id="modal-center"
        hide-footer
        centered
        no-close-on-backdrop
        content-class="shadow"
        v-model="show_model"
      >
        <div>
          <b-col md="12" xl="12">
            <div
              class="amount-payable checkout-options"
              v-if="
                payment_method !== 'QrCode' && count_card_credit == 0 && insert == true
              "
            >
              <b-alert variant="success" show>
                <div class="alert-body">
                  <span>
                    <feather-icon icon="ShieldIcon" size="21" class="mr-75" />
                    ข้อมูลบัตรเครดิตของท่านได้ถูกเก็บรักษาด้วยการเข้ารหัส ซึ่งมีความปลอดภัยตามมาตรฐานสากล โดย Socio ไม่สามารถเข้าถึงข้อมูลบัตรเครดิตของท่านได้
                  </span>
                </div>
              </b-alert>
              <b-form>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="หมายเลขบัตร"
                      label-for="payment-card-number"
                      class="mb-2"
                    >
                      <cleave
                        id="payment-card-number"
                        v-model="form.cardNumber"
                        class="form-control"
                        :raw="false"
                        :options="options.creditCard"
                        placeholder="2133 3244 4567 8921"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col sm="6">
                    <b-form-group
                      label="วันหมดอายุ(ดด/ปป)"
                      label-for="expiry"
                      class="mb-2"
                    >
                      <cleave
                        v-model="form.expiry"
                        class="form-control"
                        :raw="false"
                        :options="options.expiry"
                        placeholder="99/99"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group label="CVV" label-for="cvv" class="mb-2">
                      <input
                        class="form-control"
                        v-model="form.cvv"
                        type="password"
                        placeholder="123"
                        maxlength="3"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="ชื่อเจ้าของบัตร" label-for="name" class="mb-2">
                      <b-form-input id="name" v-model="form.cardName" />
                    </b-form-group>
                  </b-col>
                  <br />
                  <b-col cols="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      block
                      variant="primary"
                      @click="save_card()"
                    >
                      บันทึก
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
              <hr />
            </div>

            <div align="center" v-if="qrshow && insert == false">
              <div align="center" style="width: 280px">
                <img
                  :src="require('@/assets/images/logo/thai_qr_payment.png')"
                  width="100%"
                />
                <div v-for="item in data" :key="item.name">
                  <h2>แพ็กเกจ &nbsp;{{ item.name }}</h2>
                  <h3>
                    จำนวนเงิน
                    {{ new Intl.NumberFormat("en-IN").format(amount) }}
                    บาท
                  </h3>
                </div>

                <qrcode-vue :value="resQR.data.qrUrl" size="280" level="L" />

                <h4 class="mt-4">
                  หลังจากจ่ายเงินผ่าน QR เรียบร้อยแล้วกรุณารอสักครู่.......
                </h4>
              </div>
            </div>
          </b-col>
        </div>
      </b-modal>

      <template #overlay>
        <div class="text-center">
          <div class="loading">
            <div class="effect-1 effects"></div>
            <div class="effect-2 effects"></div>
            <div class="effect-3 effects"></div>
          </div>
          <div style="margin-top: 15em">
            <h4 id="cancel-label">กรุณารอสักครู่</h4>
            <h4 id="cancel-label">กำลังทำรายการ...</h4>
          </div>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BFormGroup,
  BFormRadio,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BAlert,
  BForm,
  BOverlay,
  BModal,
  VBModal,
     BFormFile,
    BImg,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import api from "@/api";
import Ripple from "vue-ripple-directive";
import modelAddress from "./model_address.vue";
import modelAddCard from "./model_add_card.vue";
import products from "./products.vue";
import QrcodeVue from "qrcode.vue";
export default {
  components: {
    QrcodeVue,
    modelAddCard,
    modelAddress,
    products,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BForm,
    BButton,
    BRow,
    BCol,
    BAlert,
    Cleave,
    BOverlay,
    BModal,
       BFormFile,
    BImg,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      insert: false,
      show_model: false,
      timer: null,
      show: false,
      qrshow: false,
      resQR: [],
      data: [],
      banks: [],
      months: [],
      months_data: [],
      set_month: 10,
      set_bank: null,
      authorize_uri: null,
      message_status: null,
      count_card_credit: 100,
      card_credit: null,
      id_card_credit: null,
      form_null: {
        cardNumber: 4242424242424242,
        expiry: "0330",
        cvv: 123,
        cardName: "JOHN DOE",
      },
      form: {
        cardNumber: null,
        expiry: null,
        cvv: null,
        cardName: null,
      },
      set_credit: null,
      set_installment: null,
      price_total: null,

      payment_method: "card",
      cardNumber: "",
      Remember_me: true,
      options: {
        creditCard: {
          creditCard: true,
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        expiry: {
          delimiter: " / ",
          blocks: [2, 2],
          uppercase: true,
        },
      },
        fileImage: null,
      image: null,
    };
  },
  watch: {
    set_bank() {
      if (this.$refs.products.quantity * this.$refs.products.price < 5000) {
        this.months = [];
        for (let i = 0; i < this.months_data.length; i++) {
          if (this.months_data[i] < 5) {
            this.months.push(this.months_data[i]);
          }
        }
      } else {
        this.months = this.months_data;
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    this.loaddata();
  },
  methods: {
    async loaddata() {
      api
        .post("user_card_by_omise", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.banks = response.data.banks;
          this.count_card_credit = response.data.count_card_credit;
          this.card_credit = "****" + response.data.result.last_digits;
          this.id_card_credit = response.data.result.id;
          this.set_credit = "****" + response.data.result.last_digits;
          this.set_installment = "****" + response.data.result.last_digits;
        })
        .catch((error) => {
          console.log("error :>> ", error);
        });
    },
    set_card(data, status) {
      this.show_model = true;
      this.insert = true;
      if (status === 0) {
        this.form.cardNumber = data.cardNumber;
        this.form.expiry = data.expiry;
        this.form.cvv = data.cvv;
        this.form.cardName = data.cardName;
      } else {
        this.form.cardNumber = null;
        this.form.expiry = null;
        this.form.cvv = null;
        this.form.cardName = null;
      }

      var omiseToken = document.querySelector('input[name="omiseToken"]');
    },
    save_card() {
      if (
        this.form.cardNumber &&
        this.form.expiry &&
        this.form.cvv &&
        this.form.cardName
      ) {
        this.show = true;
        this.show_model = false;
        api
          .post("omise_addCustomers", this.form, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            this.loaddata();
            this.show = false;
            this.show_model = false;
            console.log("response.data :>> ", response.data);
            this.$swal({
              title: "เพิ่ม Credit Card สำเร็จ",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          })
          .catch((error) => {
            this.show = false;
            this.show_model = true;
            this.$swal({
              title: "Error!",
              text: " โปรดลองใหม่อีกครั้ง!",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      } else {
        this.show = false;
        this.show_model = true;
        this.$swal({
          title: "Error!",
          text: " ตรวจสอบข้อมูลให้เรียบร้อย!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
    omise_token_payments() {
      this.show = true;
      const params = {
        product_id: this.$route.query.id,
        quantity: this.$refs.products.quantity,
        id_address: this.$refs.address.set_address,
        id_card_credit: this.id_card_credit,
      };
      console.log("params :>> ", params);
      if (this.id_card_credit && this.$refs.address.set_address) {
        api
          .post("omise_token_payments", params, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log("response.data :>> ", response.data);
            this.authorize_uri = response.data.result.authorize_uri;
            this.message_status = response.data.result.status;
            console.log("this.authorize_uri :>> ", this.authorize_uri);
            console.log("this.message_status :>> ", this.message_status);
            if (this.authorize_uri) {
              window.location.assign(this.authorize_uri);
              // this.$swal({
              //   title: "ชำระเงินสำเร็จ!",
              //   text: "สร้างรายการรับผ่อนชำระสินค้า!",
              //   icon: "success",
              //   customClass: {
              //     confirmButton: "btn btn-primary",
              //   },
              //   buttonsStyling: false,
              // });
            } else {
              this.show = false;
              this.$swal({
                title: "Error!",
                text: " โปรดลองใหม่อีกครั้ง!",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          })
          .catch((error) => {
            this.show = false;
            this.$swal({
              title: "Error!",
              text: " โปรดลองใหม่อีกครั้ง!",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      } else {
        this.show = false;
        if (this.$refs.address.set_address == null) {
          this.$swal({
            title: "Error!",
            text: "กรุณาเลือกที่อยู่ในการจัดส่ง!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      }
    },
    omise_Create_installment_payment() {
      this.show = true;
      const params = {
        product_id: this.$route.query.id,
        quantity: this.$refs.products.quantity,
        id_address: this.$refs.address.set_address,
        bank: this.set_bank,
        month: this.set_month,
      };
      console.log("params :>> ", params);

      if (this.set_bank && this.set_month && this.$refs.address.set_address) {
        console.log("params :>> ", params);
        api
          .post("omise_Create_installment_payment", params, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log("response.data :>> ", response.data);
            this.authorize_uri = response.data.result.authorize_uri;
            this.message_status = response.data.result.status;
            console.log("this.authorize_uri :>> ", this.authorize_uri);
            console.log("this.message_status :>> ", this.message_status);
            if (this.authorize_uri) {
              window.location.assign(this.authorize_uri);
              //   this.$swal({
              //     title: "ชำระเงินสำเร็จ!",
              //     text: "สร้างรายการรับผ่อนชำระสินค้า!",
              //     icon: "success",
              //     customClass: {
              //       confirmButton: "btn btn-primary",
              //     },
              //     buttonsStyling: false,
              //   });
            } else {
              this.show = false;
              this.$swal({
                title: "Error!",
                text: " โปรดลองใหม่อีกครั้ง!",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          })
          .catch((error) => {
            this.show = false;
            this.$swal({
              title: "Error!",
              text: " โปรดลองใหม่อีกครั้ง!",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      } else {
        this.show = false;
        if (this.set_bank == null) {
          this.$swal({
            title: "Error!",
            text: "กรอกข้อมูลให้ครบถ้วน!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (this.set_month == null) {
          this.$swal({
            title: "Error!",
            text: " กรอกข้อมูลให้ครบถ้วน!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (this.$refs.address.set_address == null) {
          this.$swal({
            title: "Error!",
            text: "กรุณาเลือกที่อยู่ในการจัดส่ง!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      }
    },
    paymentQR() {
      this.insert = false;
      if (this.qrshow) {
        this.show_model = true;
      } else {
        this.show = true;
        const params = {
          product_id: this.$route.query.id,
          quantity: this.$refs.products.quantity,
          id_address: this.$refs.address.set_address,
        };
        console.log("params :>> ", params);
        if (this.$refs.address.set_address) {
          api
            .post("paymentQR", params, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              this.show = false;
              console.log("response.data :>> ", response.data);
              this.qrshow = response.data.data.qrUrl;
              this.resQR = response.data;
              this.show_model = true;
              console.log("this.qrshow :>> ", this.qrshow);
              this.timer = setInterval(() => {
                api
                  .post("/checkpayment", this.resQR.data)
                  .then((response2) => {
                    console.log(response2.data);
                    if (response2.data == "OK") {
                      clearInterval(this.timer);
                      console.log(" referenceNo :>> ", this.resQR.data.referenceNo);
                      window.location.assign(
                        window.location.origin +
                          "/purchase?source=" +
                          this.resQR.data.referenceNo
                      );
                    }
                  })
                  .catch((error2) => {});
              }, 5000);
            })
            .catch((error) => {
              this.show = false;
              this.$swal({
                title: "Error!",
                text: " โปรดลองใหม่อีกครั้ง!",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        } else {
          this.show = false;
          if (this.$refs.address.set_address == null) {
            this.$swal({
              title: "Error!",
              text: "กรุณาเลือกที่อยู่ในการจัดส่ง!",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        }
      }
    },

     PromptPay() {
      if (!this.$refs.address.set_address && !this.fileImage) {
        this.$swal({
          title: "เลือกที่อยู่จัดส่ง!",
          text: " กรุณาเลือกที่อยู่จัดส่ง!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: "ยืนยันคำสั่งซื้อ ?",
          text:
            this.$refs.products.products.name +
            " จำนวน " +
            this.$refs.products.quantity +
            " เครื่อง ในราคา " +
            new Intl.NumberFormat("en-IN").format(this.$refs.products.products.price) +
            " บาท ยอดรวม  " +
            new Intl.NumberFormat("en-IN").format(
              this.$refs.products.quantity * this.$refs.products.products.price
            ) +
            " บาท",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.insert = false;
            if (this.qrshow) {
              this.show_model = true;
            } else {
              this.show = true;
              const params = {
                product_id: this.$route.query.id,
                quantity: this.$refs.products.quantity,
                id_address: this.$refs.address.set_address,
        
              };
              console.log("params :>> ", params);
              let formData = new FormData();

              formData.append("file", this.fileImage);
              formData.append("product_id", this.$route.query.id);
              formData.append("quantity", this.$refs.products.quantity);
              formData.append("id_address", this.$refs.address.set_address);
            

              if (this.$refs.address.set_address) {
                api
                  .post("payment_PromptPay_web", formData, {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("accessToken"),
                      "Content-Type": "application/json",
                    },
                  })
                  .then((response) => {
                   console.log('response.data :>> ', response.data);
                   window.location.assign(
                        window.location.origin +
                          "/purchase?source=" +
                         response.data
                      );



                  })
                  .catch((error) => {
                    this.show = false;
                    this.$swal({
                      title: "Error!",
                      text: " โปรดลองใหม่อีกครั้ง!",
                      icon: "error",
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: false,
                    });
                  });
              } else {
                this.show = false;
                if (this.$refs.address.set_address == null) {
                  this.$swal({
                    title: "Error!",
                    text: "กรุณาเลือกที่อยู่ในการจัดส่ง!",
                    icon: "error",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                }
              }
            }
          }
        });
      }
    },
    onFileChange(e) {
      this.fileImage = e.target.files[0];
      console.log("fileImage", this.fileImage);
      const image = e.target.files[0];
      this.image = URL.createObjectURL(image);
    },
  },
};
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}
</style>
