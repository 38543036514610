<template>
  <b-card>
    <b-row>
      <b-col xl="6" md="12">
        <span style="font-size: 20px">สั่งซื้อสินค้าแล้ว</span><br />
        <img :src="products.image" width="50px" />

        <span style="font-size: 17px">{{ products.name }}</span>
        <div style="font-size: 12px" v-html="products.detail"></div>
        <!-- {{ products }} -->
      </b-col>
      <b-col xl="6" md="12">
        <table class="mt-1" >
          <tr>
            <th align="center">ราคาต่อหน่วย</th>
            <th align="center">จำนวน</th>
            <th align="center">รายการย่อย</th>
          </tr>
          <br />
          <tr>
            <td align="center">
              {{ new Intl.NumberFormat("en-IN").format(products.price) }} บาท
            </td>
            <td align="center">
              <b-form-spinbutton
                id="sb-maxmin"
                v-model="quantity"
                min="1"
                max="100"
              />
            </td>
            <td align="center">
              {{ new Intl.NumberFormat("en-IN").format(products.price * quantity) }}
              บาท
            </td>
          </tr>
          <!-- <tr>
            <td>Centro comercial Moctezuma</td>
            <td>Francisco Chang</td>
            <td>Mexico</td>
        </tr> -->
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BListGroupItem,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BCardCode,
  BFormFile,
  BImg,
  BMedia,
  VBModal,
  BFormDatepicker,
  BCarousel,
  BCarouselSlide,
  BContainer,
  BFormText,
  BAlert,
  BFormSpinbutton,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

import api from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BCardCode,
    BFormFile,
    BListGroupItem,
    BCardTitle,
    BImg,
    BMedia,
    VBModal,
    BFormDatepicker,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BFormText,
    BAlert,
    BFormSpinbutton,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      products: [],
      quantity: 1,
      price: 0,
    };
  },
  watch: {},
  mounted() {
    this.loadProduct();
  },
  created() {},
  computed: {},
  methods: {
    loadProduct() {
      const params = {
        id: this.$route.query.id,
      };

      api.post("/products_eidt", params).then((response) => {
        console.log(response.data.data[0]);
        this.products = response.data.data[0];
        this.price = response.data.data[0].price;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
th, td {
    padding: 15px;
    text-align: center;
  }
</style>
